
import  Navbar  from "./components/Navbar";
import { Fragment } from "react";
import  TopBanner  from "./components/TopBanner";
import Main  from "./components/Main";
import  Footinner  from "./components/Footinner";
import Footer  from "./components/Footer";
import ReactDOM from 'react-dom';

function Home() {
  return (
    <Fragment>
      <div className="container-inner">
        <Navbar />
        <TopBanner />
      </div>
      <Main />
      <Footinner />
      <Footer />
    </Fragment>
  );
}

ReactDOM.render(<Home/>, document.getElementById('root'));


