import { Fragment, FC } from "react";



 const Navbar: FC = () => {
  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-white nav-pad">
        <a className="navbar-brand" href="#">
          <img className="logo" src="images/logo.svg" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse nav-mobile navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="#">
                Features <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Resources
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto mb-top">
            <li className="nav-item">
              <a className="nav-link special" href="#">
                Login
              </a>
            </li>

            <li className="nav-item active">
              <a className="btn btn-info signup larges">Sign Up</a>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};


export default Navbar;