import { Fragment, FC } from "react";

 const Footer: FC = () => {
  return (
    <Fragment>
      <div className="footer mx-auto">
        <div className="container-inner" style={{ paddingBottom: "30px" }}>
          <div className="row top-pad">
            <div className="col-xl-4">
              <h1 className="logo-next">Shortly</h1>
            </div>
            <div className="col-xl-2">
              <div className="link-foot link-title">Feature</div>
              <a className="link-foot" href="#">
                Link Shortening
              </a>
              <a className="link-foot" href="#">
                Branded Links
              </a>
              <a className="link-foot" href="#">
                Analytics
              </a>
            </div>
            <div className="col-xl-2">
              <div className="link-foot link-title">Feature</div>
              <a className="link-foot" href="#">
                Link Shortening
              </a>
              <a className="link-foot" href="#">
                Branded Links
              </a>
              <a className="link-foot" href="#">
                Analytics
              </a>
            </div>
            <div className="col-xl-2">
              <div className="link-foot link-title">Company</div>
              <a className="link-foot" href="#">
                About
              </a>
              <a className="link-foot" href="#">
                Our Team
              </a>
              <a className="link-foot" href="#">
                Careers
              </a>
              <a className="link-foot" href="#">
                Contact
              </a>
            </div>
            <div className="col-xl-2">
              <div className="social">
                <a href="">
                  <img
                    className="log-brand"
                    src="/images/icon-facebook.svg"
                    alt=""
                  />
                </a>
                <a href="">
                  <img
                    className="log-brand"
                    src="/images/icon-twitter.svg"
                    alt=""
                  />
                </a>
                <a href="">
                  {" "}
                  <img
                    className="log-brand"
                    src="/images/icon-pinterest.svg"
                    alt=""
                  />
                </a>
                <a href="">
                  <img
                    className="log-brand"
                    src="/images/icon-instagram.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;