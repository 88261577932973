import { FC } from "react";

 const TopBanner: FC = () => {
  return (
    <div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 order-sm-2 order-xl-1 order-lg-1 order-md-2">
          <h1 className="banner-text">More than just shorter links</h1>
          <p className="text-muted">
            Build your brand's recognition and get detailed insights on how your
            links are performing.
          </p>
          <br />
          <button className="btn btn-lg btn-info started ">Get Started</button>
        </div>
        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 order-sm-1 order-xl-2 order-lg-2 order-md-1">
          <img src="images/illustration-working.svg" className="ban" alt="" />
        </div>
      </div>
    </div>
  );
};
export default TopBanner;