import { Fragment, FC} from "react";

  const Cards: FC = ()=> {
  return (
    <Fragment>
      <div
        className="container-inner"
        style={{ marginTop: "40px", paddingBottom: "50px" }}
      >
        <div className="row">
          <div className="col-xl-4">
            <div className="card card-space">
              <div className="circle">
                <img
                  className="img-cir"
                  src="/images/icon-brand-recognition.svg"
                  alt=""
                />
              </div>
              <div className="lineone"></div>
              <h4 className="card-t">Brand Recognition</h4>
              <br />
              <p className="text-muted text-card">
                Boost your brand recognition with each click. Generic links
                don’t mean a thing. Branded links help instil confidence in your
                content.
              </p>
            </div>
          </div>
          <div className="col-xl-4" style={{ marginTop: "2rem" }}>
            <div className="card card-space">
              <div className="circle">
                <img
                  className="img-cir"
                  src="/images/icon-detailed-records.svg"
                  alt=""
                />
              </div>
              <div className="linetwo"></div>
              <h4 className="card-t">Detailed Records</h4>
              <br />
              <p className="text-muted text-card">
                Gain insights into who is clicking your links. Knowing when and
                where people engage with your content helps inform better
                decisions.
              </p>
            </div>
          </div>

          <div className="col-xl-4 third" style={{ marginTop: "4rem" }}>
            <div className="card card-space">
              <div className="circle">
                <img
                  className="img-cir"
                  src="/images/icon-fully-customizable.svg"
                  alt=""
                />
              </div>
              <h4 className="card-t">Fully Customizable</h4>
              <br />
              <p className="text-muted text-card">
                Improve brand awareness and content discoverability through
                customizable links, supercharging audience engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}


export default Cards;