import { Fragment, FC } from "react";

 const Footinner: FC = () => {
  return (
    <Fragment>
      <div className="foot-inner text-center">
        <h1 className="text-inner">Boost your links today</h1>
        <br />
        <a className="btn btn-lg btn-info btn-start">Get Started</a>
      </div>
    </Fragment>
  );
};


export default Footinner;