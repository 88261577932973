import { FC, Fragment, useState, useEffect } from "react";
import Cards from "./Cards";
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SpinnerDiamond } from "spinners-react";

const Api = axios.create({
  baseURL: "https://api.shrtco.de/v2/",
  headers: {
    "Content-Type": "application/json",
  }
});

const Refresh = ()=>{
 return setTimeout(() => {
   alert("Sorry, Something is Broken");
    window.location.reload();
  }, 7000);
}

 const Main: FC = () => {
  const [formData, setFormData] = useState({
    link: ""
  });

  var [loading, setLoading] = useState(false);
  const [css, SetCss] = useState("");
  const [errmessage, seterrmessage] = useState(false);
  const { link } = formData;

  const [History, SetHistory] = useState([]);
  const [isCopied, setIsCopied] = useState({
    value: "",
    copied: false,
  });

  useEffect(() => {
    var dataLoad = JSON.parse(localStorage.getItem("DataSet"));
    SetHistory(dataLoad);
  }, []);  

  const onChange = (e) =>
  setFormData({ ...formData, [e.target.name]: e.target.value });
  const [stat, setStat] = useState(false);
  const Logger = async () => {
    if (formData.link === "") {
      SetCss("error");
      seterrmessage(true);
      setTimeout(() => {
        seterrmessage(false);
        SetCss("");
      }, 2000);
    } else {
      Refresh();
      setLoading(true);
      var url = "/shorten?url=" + formData.link;
      try {
        let fetch = await Api.post(url);
        var dataset = fetch.data.result;
        var shortlink = dataset.short_link;
        var originalLink = dataset.original_link;
        var obj = { link: originalLink, ShortLink: shortlink };
        if (History === null) {
          var array = [];
          array.push(obj);
          localStorage.setItem("DataSet", JSON.stringify(array));
        } else {
          var array = History;
          array.push(obj);
          localStorage.setItem("DataSet", JSON.stringify(array));
        }
        window.location.reload();
      } catch (error) {
         console.log(error);
         return Promise.reject(error)
      }
    }
  };
  const Layout = () => {
    if (History) {
      return (
        <div>
          {History.map((item, key) => {
            return (
              <div className="box-show" key={key}>
                <div className="row" style={{ paddingTop: "14px" }}>
                  <div className="col-xl-8 col-lg-5 col-md-6 col-sm-6 text-dark text-left">
                    {" "}
                    <p style={{ paddingLeft: "30px" }}>
                      <b>{item.link}</b>
                    </p>{" "}
                  </div>
                  <div className="col-xl-2 col-lg-5 col-md-3 col-sm-3 text-info point">
                  <div className="mobo">
                  <a  className="lms"
                      onClick={() =>
                        window.open(`https://${item.ShortLink}`, "_blank")
                      }
                    >
                      {item.ShortLink}
                    </a>{" "}

                  </div>
                    
                  </div>
                  <div className="mobo2">
                  {
                    <CopyToClipboard text={`https://${item.ShortLink}`}>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                        {isCopied.value === `https://${item.ShortLink}` ? (
                          <button
                            onClick={() => {
                              setIsCopied({
                                value: `https://${item.ShortLink}`,
                                copied: true,
                              });
                            }}
                            className={`btn btn-dark btn-copy copy`}
                          >
                            Copied!
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setIsCopied({
                                value: `https://${item.ShortLink}`,
                                copied: true,
                              });
                            }}
                            className={`btn btn-info btn-info copy`}
                          >
                            Copy
                          </button>
                        )}
                      </div>
                    </CopyToClipboard>
                  }
                </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="bg-main">
        <div className="container-inner mx-auto text-center">
          <div className="hologram">
            <div className="container">
              <div className="row mt-3">
                <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8">
                  <input
                    name="link"
                    onChange={(e) => onChange(e)}
                    className={`form-control ${css}`}
                    placeholder="shorten a link here..."
                  />
                  {errmessage === false ? (
                    <div> </div>
                  ) : (
                    <div>
                      <p
                        style={{ marginTop: "10px" }}
                        className="text-danger text-left lead"
                      >
                        Enter a valid Link
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                  <button
                    style={{ borderRadius: "7px" }}
                    onClick={() => {
                      Logger();
                    }}
                    className="btn b-text btn-info "
                  >
                    {loading ? (
                      <div>
                        <SpinnerDiamond
                          thickness={200}
                          color={"#fff"}
                          size={22}
                        />
                       &nbsp; Shorten it!
                      </div>
                    ) : (
                      <div> Shorten it!</div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="box">{Layout()}</div>
        </div>
        <div className="text-center">
          <h1 className="adv">Advanced Statistics</h1>
          <br />
          <p className=" text-muted lead">
            Track how your links are performing across the web <br /> with our
            advanced statistics dashboard.
          </p>
        </div>
        <Cards />
      </div>
    </Fragment>
  );
};



export default Main;